import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'

const routes = [
  {path: '/', name: 'Login', component: Login},
  {path: '/:code', name:'Dashboard', component: () => import("../views/Dashboard.vue")},
  {path: '/compacto/:code', name:'DashboardJN', component: () => import("../views/DashboardJN.vue")}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
