<template>
    <div class="container main-container mt-5" style="background-color: #282e4d;">
        <div class="row justify-content-center">
            <img src="../../assets/logo.png" alt="CNC Monitor logo" class="img-fluid col-8">
        </div>
        <form @submit.prevent="doLogin">
            <div class="row my-4 text-center justify-content-center">
                <h5 class="col-12" style="color: #3bb3c3">Inform dashboard code</h5>
                <div class="col-6">
                    <input 
                        type="text" 
                        class="form-control text-center" 
                        step="1"
                        autofocus 
                        v-model="code"
                        @keypress="clearTimeout"
                        :disabled="loading">
                </div>
            </div>
            <div class="row justify-content-center">
                <button class="btn btn-primary col-5 col-md-4" :disabled="loading">
                    <template v-if="!loading">Access</template>
                    <template v-else><i class="fa fa-spinner fa-spin"></i> Loading...</template>
                </button>
            </div>
        </form>
        <!-- <div class="mt-5 text-center" v-if="autoLogin.enabled">
            <p style="color: #3bb3c3">Automatic access using the code above in 10 seconds...</p>
            <div class="progress">
                <div 
                    class="progress-bar progress-bar-striped progress-bar-animated" 
                    :style="{width: autoLogin.width+'%'}"
                    role="progressbar" 
                    aria-valuenow="10" 
                    aria-valuemin="0" 
                    aria-valuemax="10"></div>
            </div>
        </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
import WebSocketClient from "../../classes/WebSocketClient";
import HandlersFactory from '../../classes/backendHandlers/HandlersFactory';

export default {
    name:'LoginFormBootstrap',
    data() {
        return {
            code: '',
            loading: false,
            autoLogin: {
                enabled: true,
                timer: null,
                width: 100
            },
            backend: null
        }
    },
    watch: {
        code() {
            if (this.autoLogin.enabled) {
                this.autoLogin.enabled = false
                window.clearTimeout(this.autoLogin.timer)
            }
        }
    },
    methods: {
        doLogin() {
            const json = {
                acao: 100,
                token: this.code
            }
            this.backend.onSendMessage(json)    
        },
        onBackendConnected(res) {
            console.log(res)
        },
        onBackendDisconnected(res) { 
            console.log(res)
        },
        OnMessageBeforehandlers(message) {
            const codigo = message[0].codigo
            if (codigo == 200) {
                this.$router.push(`/${this.code}`)
            }
        },
        connectToBackend() {
            this.backend = new WebSocketClient(
                this.onBackendConnected,
                this.onBackendDisconnected,
                this.OnMessageBeforehandlers
            );

            this.backend.addHandlers(HandlersFactory(this));
        }
    },
    created() {
        this.connectToBackend()
    },
    unmounted() {
        window.clearTimeout(this.autoLogin.timer)
    }
}
</script>

<style scoped>
.main-container {max-width: 500px}
</style>